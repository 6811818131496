.mainFilterContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    background-image: url('../../Images/filter-wallpaper.jpg');
    margin: 0 auto;
    box-shadow: 50px 50px 50px rgba(0, 0, 0, 0.15);
    width:30vw;
    height: 100%;
    opacity: 0.7;
    padding-bottom:15px;
    text-align: center;
    margin-bottom: 15px;
    position:relative;
}
.mainFilterContainer h2{
    font-size: 2rem;
    font-family: 'Goldman', cursive;
    color: rgb(255, 255, 255);
}
.filter{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;
}

.buttonFilter{
    margin: 0 auto;
    border:none;
    background-color: rgb(49, 151, 199);
    color:rgb(0, 0, 0);
    font-weight: bold;
    width: 8vw;
    height: 5vh;
    font-family: 'Goldman', cursive;
    font-size: 1rem;
    border-radius: 10px;
    letter-spacing: 1px;

}

.buttonFilter{
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.buttonFilter:hover, .buttonFilter:focus, .buttonFilter:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    outline: none;
}


#selectFilter{
    height: 4vh;
    width: 8vw;
    border: none;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    background-color: #6bb6fc;
    font-family: 'Orbitron', sans-serif;
    margin:0 auto;
    text-align: center;
}

#selectFilter:focus{
outline: none;
}

.classFilter{
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media (max-width: 2000px)and (min-width: 800px){

    .filter{
        flex-direction: column;
    }
    .buttonFilter{
        width: 20vw;
        margin-top: 20px;
    }
}

@media (max-width: 800px)and (min-width: 300px){
    .mainFilterContainer{
        width: 100%;
    }
    .mainFilterContainer h2{
        font-size: 1.4rem;
    }
    .filter{
        flex-direction: column;
        height: 30vh;
    }
    #selectFilter{
        width: 30vw;
    }

    
    .buttonFilter{
        width: 35vw;
        font-size: 0.7rem;
        margin-top: 15px;
    }
}

@media (max-width: 1000px)and (min-width: 800px){
    .mainFilterContainer{
        width: 50vw;
    }
    #selectFilter{
        width: 15vw;
    }

    .buttonFilter{
        width: 15vw;
        height: 5vh;
        font-size: 0.8rem;
        margin-top: 20px;
    }
}
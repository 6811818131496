.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 10vh;
    text-align: center;
    position: absolute;
   
}
.mainFooterContainer{
    margin-top: 500px;
}
.footer img{
width: 4vw;
height: 7vh;
padding-left: 50px;
transition: 0.70s;
-webkit-transition: 0.70s;
-moz-transition: 0.70s;
-ms-transition: 0.70s;
-o-transition: 0.70s;
}

.footer img:hover {
    transition: 0.70s;
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    }

    @media (max-width: 1600px) and (min-width: 700px){
        .footer img{
            width: 6vw;
        }
    }
    @media (max-width: 700px) and (min-width: 300px){
        .footer img{
            width: 15vw;
            padding-left: 15px;
        }
    }
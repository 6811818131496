
.navbar {
    background:radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    height: 80px;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
  }

  .titleNavContainer{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .userNav{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 10vw;
  }
  .userNav h2{
    font-family: 'Gloria Hallelujah', cursive;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: rgb(255, 255, 255);
  }
  .titleNavContainer h1{
    color: rgb(255, 255, 255);
    letter-spacing: 8px;
    font-size: 30px;
    text-transform: uppercase;
    font-family: 'Righteous', cursive;
    text-decoration: none;
  }
  .menu-bars {
    margin-right: 5rem;
    font-size: 2rem;
    background: none;
    font-family: 'Righteous', cursive;
    text-decoration: none;
  }

  .nav-menu {
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    background: radial-gradient(circle, rgb(239, 147, 187) 0%, rgb(117, 173, 237) 100%);
    font-family: 'Cormorant Unicase', serif;
    z-index: 2000;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background:radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  .logo{
    width: 150px;
    height: 5vh;
  }
  @media (max-width: 600px) and (min-width: 200px) {
    .titleNavContainer h1{
    display: none;
    }
    .navbar {
      justify-content: flex-end;
    }
    .userNav{
      text-align: center;
    }
    .titleNavContainer{
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
    }
}
.blue { color: #324d5a; }
.yellow { color: #FFF842; }
.container{
    text-align: center;
}

.container th h1 {
	font-weight: bold;
	font-size: 1em;
    text-align: left;
    color: #185875;
    text-align: center;
    font-size: 30px;
}

.container td {
	font-weight: normal;
	font-size: 1em;
    -webkit-box-shadow: 0 2px 2px -2px #0E1119;
	-moz-box-shadow: 0 2px 2px -2px #0E1119;
	box-shadow: 0 2px 2px -2px #0E1119;
    text-align: center;
    color:rgb(250, 92, 92);
    font-size: 35px;
    font-family: 'Orbitron', sans-serif;
}

.container {
	text-align: left;
	overflow: hidden;
	width: 70%;
	margin: 0 auto;
    display: table;
    padding: 0 0 8em 0;
}

.container td, .container th {
	padding-bottom: 2%;
	padding-top: 2%;
    padding-left:2%;  
}

.container tr:nth-child(odd) {
	background-color: #323C50;
}


.container tr:nth-child(even) {
	background-color: #2C3446;
}

.container th {
	background-color: #000000;
}

.container td:first-child { 
    color: #98dd9d; 
    font-size: 35px;
    font-family: 'Orbitron', sans-serif;
}
#negativeAmout{
    color:rgb(250, 92, 92);
}
.container tr:hover {
    background-color: #000000;
    -webkit-box-shadow: 0 6px 6px -6px #0E1119;
	-moz-box-shadow: 0 6px 6px -6px #0E1119;
	box-shadow: 0 6px 6px -6px #0E1119;
}

.container td:hover {
    background-color: #f7f5c9;
    color: #000000;
    font-weight: bold;
    box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
    transform: translate3d(6px, -6px, 0);
    transition-delay: 0s;
	transition-duration: 0.4s;
	transition-property: all;
    transition-timing-function: line;
}


@media (max-width: 700px)and (min-width: 300px){
.main-container-table h1{
    font-size: 1.8rem;
}
}
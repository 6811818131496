.formRecordContainer{
display: flex;
justify-content: center;
align-items: center;
width: 80vw;
margin:0 auto;
}

.styledTable {
    margin: 0 auto;
    font-size: 1.5em;
    font-family: 'Goldman', cursive;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width:70vw;
}
.styledTable thead tr {
    background-color: #0595d8;
    color: #ffffff;
    text-align: center;
}

.styledTable th,
.styledTable td {
    padding: 12px 15px;
    color: rgb(0, 0, 0);
}
.styledTable td {
    opacity: 0.8;
}

.styledTable tbody tr:nth-of-type(even) {
    background-color: #ffffff;
    text-align: center;
}

.styledTable tbody tr:nth-of-type(odd) {
    background-color: #d9eef1;
    text-align: center;
}


.styledTable tbody tr.active-row {
    border-radius: 15px;
    text-align: center;
}

.operationButton{
    border:none;
    background-color: rgb(250, 64, 64);
    color:rgb(0, 0, 0);
    font-weight: bold;
    width: 100%;
    height: 4vh;
    font-family: 'Goldman', cursive;
    font-size: 1rem;
    border-radius: 10px;
    letter-spacing: 1px;
    }
    
    
    .operationButton{
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .operationButton:hover, .operationButton:focus, .operationButton:active {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        outline: none;
    }

    #editButton{
        border:none;
        background-color: rgb(69, 111, 226);
        color:rgb(0, 0, 0);
        font-weight: bold;
        width: 100%;
        height: 4vh;
        font-family: 'Goldman', cursive;
        font-size: 1rem;
        border-radius: 10px;
        letter-spacing: 1px;
        }
        
        .inputsGlobalTable{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        #editButton{
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            transition-property: transform;
        }
        #editButton:hover, #editButton:focus, #editButton:active {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            outline: none;
        }


        @media (max-width: 600px) and (min-width: 300px){
            .formRecordContainer{
                display: flex;
                flex-direction: column;
            }
    }


    @media (max-width: 1000px) and (min-width: 600px){
        .formRecordContainer{
            display: flex;
            flex-direction: column;
        }
}

    .horizontalBarGraphics h2{
        color: rgb(0, 0, 0);
        font-family: 'Orbitron', sans-serif;
        text-align: center;
    }
    

    @media (max-width: 600px) and (min-width: 200px){
            .horizontalBarGraphics h2{
                color: rgb(0, 0, 0);
                font-family: 'Orbitron', sans-serif;
                font-size: 18px;
            }
        }